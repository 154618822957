<template>
  <div>
    <title>SIM INVENTORY ~ DETAIL USERGROUP MANAGEMENT DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Detail Usergroup Management Data
        <br />
        <h4>
          The following is a list of master detail usergroup management lists
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-5">
          <!--<router-link to="/add-supplier">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Plus Data </i>
            </button>
          </router-link> -->

          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">User Menu Data</h3><b style="color:red;font-size:16px">&nbsp; {{this.isidata.namagroup}}</b>
              </div>
              <div class="box-body">
                <input
                  type="hidden"
                  readonly
                  v-model="isidata.namagroup"
                  autocomplete="off"
                  class="form-control"
                  placeholder="Group Name"
                />
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize2"
                      @change="handlePageSizeChange2($event)"
                    >
                      <option
                        v-for="size2 in pageSizes2"
                        :key="size2"
                        :value="size2"
                      >
                        {{ size2 }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Menu Name"
                      class="form-control"
                      v-model="namesearch2"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata2()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
                <!-- /.box-body -->
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist2" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Menu Name</th>
                      <th>Link</th>
                      <th>Serial</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged2"
                      :key="classdata.id"
                    >
                      <td>
                        <button
                          @click="saveData(classdata)"
                          type="button"
                          class="btn btn-primary"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                      <td>
                        {{ index + 1 + (page - 1) * pageSize }}
                      </td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nama }}</td>
                      <td>{{ classdata.link }}</td>
                      <td>{{ classdata.urutan }}</td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count2"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange2"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Menu Name</th>
                      <th>Link</th>
                      <th>Serial</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="5" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>

          <!-- <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Simpan
                </button>
              </div> -->
        </div>
        <div class="col-md-7">
          <!--<router-link to="/add-supplier">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Plus Data </i>
            </button>
          </router-link> -->

          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">List Detail Usergroup Management Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Group Name"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
                <!-- /.box-body -->
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <!-- <th>Group Name</th> -->
                      <th>Menu Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>
                        {{ index + 1 + (page - 1) * pageSize }}
                      </td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <!-- <td>{{ classdata.nama_group }}</td> -->
                      <td>{{ classdata.nama_menu }}</td>
                      <td>
                        <button
                          title="Delete User Group Detail"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <!-- <th>Group Name</th> -->
                      <th>Menu Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="3" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "readsup",
  components: {
    VPagination,
  },
  data() {
    return {
      isidata: {
        namagroup: "",
      },
      classdata: {
        id: "",
      },
      isExist: true,
      isExist2: true,
      loading: false,
      namesearch: "",
      namesearch2: "",
      list_paged: [],
      list_paged2: [],
      page: 1,
      pagestat: 1,
      count: 1,
      count2: 1,
      pageSize: 10,
      pageSize2: 10,
      pageSizes: [10, 50, 100, 500],
      pageSizes2: [10, 50, 100, 500],
    };
  },
  created() {
    this.fetchData();
    this.fetchDataMenu();
    this.isidata.namagroup = this.$route.params.id;
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },
    handlePageChange2(value) {
      this.page = value;
      this.pagestat = 1;
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchDataMenu();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    handlePageSizeChange2(event) {
      this.pageSize2 = event.target.value;
      this.page = 1;
      this.fetchDataMenu();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async saveData(classdata) {
      this.loading = true;
      var nama = this.isidata.namagroup;
      if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Group Name can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else {
        const paramdata = {
          nama_group: this.isidata.namagroup,
          menu_id: classdata.id,
        };

        const tokenlogin = sessionStorage.getItem("token");
        const urlAPISaveData =
          this.$apiurl + "group_user_detail/savegroup_user_detail";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin,
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false,
              });
              //   this.isidata.namagroup = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false,
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false,
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async fetchData() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "group_user_detail/getalldatagroup_user_detail_by_nama_group?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&nama_group=" +
        this.$route.params.id;
      console.log(urlAPIget);
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async fetchDataMenu() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "menu/getalldatamenu?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      console.log(urlAPIget);
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist2 = false;
          } else {
            this.isExist2 = true;
            this.list_paged2 = resp.data.data;
            this.count2 = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        this.$router.push({ name: "usergroupmanagement" });
        this.loading = true;
        this.fetchData();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIget =
          this.$apiurl +
          "group_user_detail/getalldatagroup_user_detail_by_nama_group?cari=" +
          this.namesearch +
          "&length=" +
          this.pageSize +
          "&nama_group=" +
          this.$route.params.id;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
            });
          });
      }
    },
    searchdata2() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "menu/getalldatamenu?cari=" +
        this.namesearch2 +
        "&length=" +
        this.pageSize2;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          this.list_paged2 = resp.data.data;
          this.count2 = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl + "group_user_detail/hapusgroup_user_detail/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully",
                }).then(function () {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed",
                }).then(function () {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
